import { useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import Logo from '~/components/nav/common_logo_wh.svg'
import Arrow from '~/images/common_icon_arrow_r.svg'
import { Seo, MenuModal, Subscription } from '~/components'
import * as s from '~/components/pages/order/order.module.scss'
import SubscriptionData from '~/json/subscription.json'
import OrderPlans from '~/json/orders.json'
import { ShopifyBuyInit } from '../lib/utils'

const PlanSection = ({ plan }) => {
  const { title, text, smallTxt, id } = plan
  return (
    <section
      className="wrap-order-plans-section acordionWrapper for-heel"
      key={id}
    >
      <header className="acordionSwitch">
        <h2 className="heading is-secondary">{title}</h2>
        <p>
          {text}
          {smallTxt && (
            <span className="smallTxt">
              <br />
              {smallTxt}
            </span>
          )}
        </p>
      </header>
      <div id={id} className="acordionContent" />
    </section>
  )
}

const ApplyPage = () => {
  const loadingRef = useRef()
  function loadingInit() {
    loadingRef.current.classList.remove('is-visible')
  }

  useEffect(() => {
    if (
      !window.location.href.includes('order') ||
      typeof window === 'undefined'
    )
      return

    loadingInit()

    // order plans
    ShopifyBuyInit({
      buyId: '275207684304',
      elementId: 'collection-component-1635133215013',
      type: 'collection',
    })
    ShopifyBuyInit({
      buyId: '275207749840',
      elementId: 'collection-component-1635132963299',
      type: 'collection',
    })
    ShopifyBuyInit({
      buyId: '275207782608',
      elementId: 'collection-component-1635132866362',
      type: 'collection',
    })
  }, [])

  const orderPlans = OrderPlans.order_plans
  const subscriptions = SubscriptionData.subscriptions

  return (
    <>
      <Seo title="order" pageUrl="order" />
      <div
        ref={loadingRef}
        id="loadingArea"
        className="orderLoading is-visible"
      >
        <div className="loadingContent">
          <div>
            <div role="img" aria-label="Loading">
              <Logo />
            </div>
            <div className="loadingBar" />
          </div>
        </div>
      </div>

      <div className="pageComponent">
        <header className="titleComponent contentComponent">
          <h1 className="heading pageTitle">ORDER</h1>
          <p>靴1足ごとに1プランご選択ください。</p>
        </header>
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>お申し込みメニュー選択</li>
          </ul>
        </nav>
        <div className={s.orderMenuDescription + ' contentComponent'}>
          <MenuModal />
        </div>
        <div className="contentComponent acordionComponent for-order">
          {orderPlans.map(plan => (
            <PlanSection plan={plan} key={plan.id} />
          ))}
          {subscriptions.map(item => (
            <Subscription key={item.id} item={item} />
          ))}
          <section class="wrap-order-plans-section acordionWrapper"><header　className="acordionOpen"><h2 class="heading is-secondary">ソール補強</h2><p>愛着あるスニーカーを長く履くための、オールソール補強です。ソールに合わせて厚さやカラーもお選びください。お申し込みは<a href="/sole/">こちら</a>から。</p></header></section>
          <section class="wrap-order-plans-section acordionWrapper"><header　className="acordionOpen"><h2 class="heading is-secondary">スニーカーラップ</h2><p>大切なスニーカーを守りたい。あなたのスニーカーを加水分解から守るために、プロの職人がラップします。ラップする前に靴専用機で目に見えないカビの元を殺菌し、スニーカー内の水分除去を施したあと、１足１足を丁寧にラッピングしていきます。コレクションする際の角度も意識して施しますので、お手元に届いた際の仕上がりをお楽しみください。お申し込みは<a href="/wrap/">こちら</a>から。</p></header></section>
        </div>
      </div>
    </>
  )
}
export default ApplyPage
